import React from "react";
const EventName = ({
  content
}) => {
  return content ? (
    <div className="sg-nllp-section sg-nllp-section-event-name">
      <div className="sg-nllp-section-container">
        <div dangerouslySetInnerHTML={{ __html: content }}/>
      </div>
    </div>
  ) : ""
}
 

export default EventName