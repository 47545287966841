import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const PersonalAgenda = ({
  language,
  kbyg_independent_content,
  current_ticket_contents
}) => {
  const header_item = getItemBySlug(kbyg_independent_content, 'kbyg-personal-agenda-headline');

  const agenda_copy_slug = language === 'fr' ? 'personal-agenda-french' : 'personal-agenda-english';
  const agenda_copy = (current_ticket_contents||{})['answers'][agenda_copy_slug]

  return (
    <div id="personal-agenda" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-agenda">
      <div className="container flex">
        <div className="sg-logged-in-landing-page-icon-side">
          <img src="/images/landing/p_agenda.svg" />
        </div>
        <div className="sg-logged-in-landing-page-copy-side">
          <div dangerouslySetInnerHTML={{__html: getCopy(header_item, {language})}} className="sg-logged-in-landing-page-section-header"/>
          <div dangerouslySetInnerHTML={{__html: agenda_copy||""}} className="sg-logged-in-landing-page-section-agenda-agenda"/>
        </div>
      </div>
    </div>
  )
}

export default PersonalAgenda