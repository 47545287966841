import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";

class SaveTheDateFormDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/save_the_date/decline_post`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy-cms"
            dangerouslySetInnerHTML={{
              __html: (getCopy(getItemBySlug(this.props.cms_section, 'rsvp-decline-confirmation'))||"")
            }}
          />
          <br/>
          <br/>
          <div className="text-left col-xs-12">
            <a target="_blank" href="mailto:jointhegames@auditoire.com">
              <button type="button" style={{paddingTop: "10px"}} className="sg-button sg-submit-button">
                Contact us
              </button>
            </a>
          </div>
        </div>
      )
    }
    return (
      <form src="#" onSubmit={this.declineEmail}>
        <div>
          <div className="row" >
            <div className="col-xs-12 sg-rsvp-copy-cms"
              dangerouslySetInnerHTML={{ __html: getCopy(getItemBySlug(this.props.cms_section, 'rsvp-decline-email-validation')) }}
            />
          </div>
        </div>
        <div>
          <br/>
          <label className="sg-rsvp-copy-field-label">Enter Email Address*</label>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="dark-form-control "
          />
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.declineEmail}
            className="sg-button sg-submit-button"
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default SaveTheDateFormDecline;
