import React from "react";
import PropTypes from "prop-types";
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
class RsvpClosed extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {cms_section} = this.props
    const cms_id = 'rsvp-closed'
    return (
      <div>
        <div class="sg-rsvp-copy">
          <div class="sg-rsvp-copy-title">
            {getTitle(getItemBySlug(cms_section, cms_id))}
          </div>
          <br/>
          <br/>
          <div dangerouslySetInnerHTML={{__html: 
            getCopy(getItemBySlug(cms_section, cms_id))
          }}/>
        </div>  
      </div>
    )
  }
}

export default RsvpClosed