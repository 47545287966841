import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const HotelAccomodations = ({
  language,
  kbyg_independent_content,
  current_ticket_contents,
  current_ticket
}) => {
  if(!current_ticket || !current_ticket_contents || current_ticket.package.id == "daily") {
    return <></>
  }

  const header_slug = language === 'fr' ? 'accomodations-headline-french' : 'accomodations-headline-english';
  const copy_slug = language === 'fr' ? 'accomodations-copy-french' : 'accomodations-copy-english';

  const header = current_ticket_contents['answers'][header_slug];
  const copy = current_ticket_contents['answers'][copy_slug];


  const dashDatesToSlashDates = (date) => {
    try {
      const dateSplit = date.split("-");
      return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
    }catch (e) {
      return ""
    }
  }

  const table = getCopy(getItemBySlug(kbyg_independent_content, 'kbyg-accomodations-content')||"", {language}).replaceAll(
    "*|check-in-time|*",
    dashDatesToSlashDates(current_ticket.ticket.start_date)
  ).replaceAll(
    "*|check-out-time|*",
    dashDatesToSlashDates(current_ticket.ticket.end_date)
  )
  return (
    <div id="accommodations" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-accomodations">
      <div className="container flex">
        <div className="sg-logged-in-landing-page-icon-side">
          <img src="/images/landing/hotels.svg" />
        </div>
        <div className="sg-logged-in-landing-page-copy-side">
          <div dangerouslySetInnerHTML={{__html: header}} className="sg-logged-in-landing-page-section-header"/>
          <div dangerouslySetInnerHTML={{__html: copy}} className="sg-logged-in-landing-page-section-copy"/>
          {current_ticket.package.id != "heritage" ? (
            <div dangerouslySetInnerHTML={{__html: table}} className="sg-logged-in-landing-page-section-table"/>
          ):""}

        </div>
      </div>
    </div>
  )
}

export default HotelAccomodations