import React from "react";
const ExclusiveExperience = ({
  content,
  anchor
}) => {
  return (
  <div id={anchor} class="sg-nllp-section sg-nllp-section-exclusive">
    <div class="sg-nllp-section-container">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
        <div class="hidden-xs hidden-sm col-md-1"></div>
        <div class="hidden-xs hidden-sm col-md-5">
        </div>
      </div>
    </div>
  </div>
  );
}
 

export default ExclusiveExperience