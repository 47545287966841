import React from "react";
import FormBuilder from "@layout/forms/FormBuilder";
import { getItemBySlug } from "@utility/cms/CmsUtility";
import { getCopy, getTitle } from "@utility/cms/LangUtility";
class RsvpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participant: props.participant,
      submission: null,
    }
    this.callbackNonBlocking = this.callbackNonBlocking.bind(this);
  }

  callback(json) {
  }

  callbackNonBlocking(values, state, form_ref, form_gid){
    this.state.submission = values
    this.setState({
      submission: values
    })
  }

  getEmailAndAssistantIfApplicable() {
    const { submission } = this.state;
    console.log({
      submission
    })
    const { participant } = this.props;
    const participantMetadataAssistantEmail = (participant.metadata||[]).find(x=> x.field_slug == "assistantemail");
    if(submission && submission && submission.participant && submission.participant.metadata.assistantemail) {
      return `${participant.email} and ${submission.participant.metadata.assistantemail}`;
    }else if(submission == null && participantMetadataAssistantEmail && participantMetadataAssistantEmail.value){
      return `${participant.email} and ${participantMetadataAssistantEmail.value}`;
    }
    return participant.email
  }
  

  getReplacableStrings() {
    const { current_ticket, participant, cms_section } = this.props;
    const { ticket } = current_ticket;
    const confirmationItem = getItemBySlug(cms_section, "rsvp-confirm-confirmation-copy-1");
    return [
      {key: '*|CUSTOM1|*', value: ticket ? `${current_ticket.package.headerDateVerb} ${ticket.full_name_html}` : ''},
      {key: '*|CONFIRMATION_TITLE|*', value: getTitle(confirmationItem)},
      {key: '*|CONFIRMATION_COPY|*', value: (getCopy(confirmationItem)||"").replace("*|EMAIL_AND_ASSISTANT_IF_APPLICABLE|*", this.getEmailAndAssistantIfApplicable()).replace("*|EMAIL|*", participant.email)},
      {key: '*|EMAIL|*', value: participant.email },
      {key: '*|EMAIL_AND_ASSISTANT_IF_APPLICABLE|*', value: this.getEmailAndAssistantIfApplicable() }
    ]
  }

  render() {
    const { participant, form } = this.props;
    return (
      <FormBuilder
        ticket_info={[]}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        onSubmitNonBlocking={this.callbackNonBlocking}
        submitUrl={'/rsvp/confirm_post'}
        submitCallback={this.callback}
        type={'registration'}
      />
    );
  }
}

export default RsvpForm;