import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import { Formik, Field, Form } from "formik";
import GeneralField from "@layout/forms/GeneralField";
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";

class SaveTheDateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      saving: false,
      checkError: false
    }
  }


  submitRegistration(values) {
    if(this.state.saving){
      return;
    }
    this.setState({
      saving: true,
      error: null,
    })
    const { submitCallback } = this.props;
    fetchAPI(`/save_the_date/confirm_post`,(json)=>{
      if(json.error) {
        this.setState({
          error: error,
          saving: false
        })
      }else {
        this.setState({
          saving: false
        })
        submitCallback(json);
      }
    }, {
      method: 'POST',
      body: {
        user: values.user
      }
    })
  }

  getInitialValues() {
    const { attendee } = this.props;
    return {
      user: {
        email: attendee.email,
        name_first: attendee.name_first,
        name_last: attendee.name_last,
        secondaryemail: "",
        company: attendee.company||"",
        job_title:attendee.job_title||"",
        work_phone: "",
        assistantnamefirst:"",
        assistantnamelast:"",
        assistantemail:"",
        assistantphone:"",
      }
    }
  }

  renderHeader() {
    const { cms_section } = this.props;
    console.log({
      cms_section,
      p: this.props,
    })
    return (
      <div className="row" >
        <div style={{ paddingBottom: "20px" }} className="col-xs-12 sg-rsvp-copy-title" dangerouslySetInnerHTML={{ __html: getCopy(getItemBySlug(cms_section, 'rsvp-header')) }}>

        </div>
      </div>
    )
  }
  renderForm(formikProps) {
    const { cms_section } = this.props;
    const { checkError } = this.state;
    return (
      <>
        {this.renderHeader()}
        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy-subtitle">
            Your Information
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              First Name
            </div>
            <GeneralField
              name="name_first"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Last Name
            </div>
            <GeneralField
              name="name_last"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>


          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Email
            </div>
            <GeneralField
              name="email"
              disabled={true}
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Job Title
            </div>
            <GeneralField
              name="job_title"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Company Name
            </div>
            <GeneralField
              name="company"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>
          
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
             Country
            </div>
            <GeneralField
              name="work_address_country"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              Mobile Telephone
            </div>
            <GeneralField
              name="work_phone"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
            />
            (Include area and country code)
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label required">
              City
              </div>
              <GeneralField
                name="work_address_city "
                objectName="user"
                checkError={checkError}
                formikProps={formikProps}
              />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy-subtitle">
            <br/>
            <br/>
            Assistant Information
            <br/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              First Name
            </div>
            <GeneralField
              name="assistantnamefirst"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Last Name
            </div>
            <GeneralField
              name="assistantnamelast"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Email
            </div>
            <GeneralField
              name="assistantemail"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sg-rsvp-copy-field-label">
              Mobile Telephone
            </div>
            <GeneralField
              name="assistantphone"
              objectName="user"
              checkError={checkError}
              formikProps={formikProps}
              optional={true}
            />
                        (Include area and country code)
          </div>
          <div className="col-xs-12 sg-rsvp-small-copy " style={{paddingTop:"20px"}} dangerouslySetInnerHTML={{ __html: getCopy(getItemBySlug(cms_section, 'rsvp-footer')) }}/>
        </div>
      </>
    )
  }

  renderError(formikProps) {
    const { checkError } = this.state;
    if (checkError && !this.isPageOneValid(formikProps)) {
      return (
        <div className="row">
          <div className="col-xs-12 sg-rsvp-copy" style={{color: "red"}}>
            <br/>
            <br/>
            Please ensure all required fields are filled out.
          </div>
        </div>
      )
    }
  }

  renderButtons(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12 sg-rsvp-copy-submi t">
          <br/>
          <button onClick={(e)=>this.attemptSubmit(formikProps)} className="sg-button sg-submit-button">
            Submit
          </button>
        </div>
      </div>
    )
  }

  attemptSubmit(formikProps) {
    if(this.isPageOneValid(formikProps)) {
      formikProps.submitForm();
    }else {
      this.setState({
        checkError: true,
      })
    }
  }
  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      'email',
      'name_first',
      'name_last',
      'company',
      'work_phone',
    ];
    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    return true
  }

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={(values, actions) => {
          this.submitRegistration(values)
        }}
        render={(formikProps) => (
          <Form
            ref={this.formikRef}
            onSubmit={e => { e.preventDefault(); e.stopPropagation(); }}
            className="sg-rsvp-form"
          >
            {this.renderForm(formikProps)}
            {this.renderError(formikProps)}
            {this.renderButtons(formikProps)}
          </Form>
      )}/>
    )

  }
}

export default SaveTheDateForm;
