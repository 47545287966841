import React from "react";
import PropTypes from "prop-types";

class CustomReportTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getReports(){
    return [
      // {name:"Master Report", url:"/admin/reports/master_report"},
      // {name:"Comparison Report", url:"/admin/reports/comparison_report"},
      {name:"Master Analysis", url:"/admin/reports/participant_master_weekly"}
      // {name:"Hotel Report", url:"/admin/reports/hotel_report"},
      // {name:"Visa Report", url:"/admin/reports/visa_report"},
      // {name:"Dietary Report", url:"/admin/reports/dietary_report"},
      // {name:"Interests Report", url:"/admin/reports/interests_report"},
      // {name:"Bounce Report", url:"/admin/reports/postmark_bounce_report"},
      // {name:"Travel Report", url:"/admin/reports/travel_report"},
    ]
  }

  renderReports() {
    return this.getReports().map(x=>
      this.renderReport(x)
    );
  }

  renderReport(report) {
    return (
      <div className="sg-admin-custom-report-container">
        <a
          href={report.url}
          target="_blank"
          className="sg-admin-custom-report-button"
        >
          <img src="/images/admin/custom-report-download.png"/>
        </a>
        <div className="sg-admin-custom-report-name">
          <a href={report.url} target="_blank">
            {report.name}
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sg-admin-custom-report-table">
        {this.renderReports()}
      </div>
    )
  }

}

export default CustomReportTable;
