import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const BeforeYouLeave = ({
  language,
  kbyg_independent_content
}) => {
  const header_item = getItemBySlug(kbyg_independent_content, 'kbyg-introduction');
  const copy_item = getItemBySlug(kbyg_independent_content, 'kbyg-introduction-copy');

  return (
    <div id="introduction" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-excite">
      <div className="container">
        <div className="flex">
          <div className="col-xs-12 col-sm-6  sg-logged-in-landing-page-section-excite-copy-section">
            <div dangerouslySetInnerHTML={{__html: getCopy(header_item, {language})}} className="sg-logged-in-landing-page-section-excite-header"/>
            <div dangerouslySetInnerHTML={{__html: getCopy(copy_item, {language})}} className="sg-logged-in-landing-page-section-excite-copy"/>
          </div>
          <div className="hidden-xs col-sm-6  sg-logged-in-landing-page-section-excite-image">
            <img src="/images/landing/fencer.png"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeforeYouLeave