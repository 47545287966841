import React from "react";
const ContactUs = ({
  content,
  anchor
}) => {
  return (
    <div id={anchor} className="sg-nllp-section sg-nllp-section-contact-us">
      <div className="sg-nllp-section-container">
        <div dangerouslySetInnerHTML={{ __html: content }}/>
      </div>
    </div>
  )
}
 

export default ContactUs