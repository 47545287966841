import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const ArrivalTransportation = ({
  language,
  kbyg_independent_content,
  current_ticket_contents,
  current_ticket,
  attendee
}) => {

  const knows_arrival_transportation = (attendee.metadata.find(x=>x.field_slug == "knowntransportplant")||{}).value == "Yes"

  if(!knows_arrival_transportation || !current_ticket || !current_ticket_contents || current_ticket_contents.answers['disable-guest-transportation'] == "Yes") {
    return <></>
  }

  const header_item = getItemBySlug(kbyg_independent_content, 'kbyg-departure-transportation-headline');

  const copySlug = language === 'fr' ? "departure-transportation-french" : "departure-transportation-english"
  const copy = current_ticket_contents['answers'][copySlug];


  const dashDatesToSlashDates = (date) => {
    try {
      const dateSplit = date.split("-");
      return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
    }catch (e) {
      return ""
    }
  }

  const attendee_place_of_departure = (attendee.metadata.find(x=>x.field_slug == "placeofdeparture")||{}).value
  const departure_time = (attendee.metadata.find(x=>x.field_slug == "provideddeparturetime")||{}).value
  const departure_flight_number = (attendee.metadata.find(x=>x.field_slug == "departuretransportationnumber")||{}).value
  const table = getCopy(getItemBySlug(kbyg_independent_content, 'departure-content')||"", {language}).replaceAll(
    "*|place-of-departure|*",
    attendee_place_of_departure
  ).replaceAll(
    "*|departure-time|*",
    departure_time
  ).replaceAll(
    "*|departure-train-flight-no.|*",
    departure_flight_number
  )
  return (
    <div id="departure" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-arrival  sg-logged-in-landing-page-section-departure">
      <div className="container flex">
        <div className="sg-logged-in-landing-page-icon-side">
          <img src="/images/landing/icon_car_2.svg" />
        </div>
        <div className="sg-logged-in-landing-page-copy-side">
          <div dangerouslySetInnerHTML={{__html: getCopy(header_item, {language})}} className="sg-logged-in-landing-page-section-header"/>
          <div dangerouslySetInnerHTML={{__html: copy}} className="sg-logged-in-landing-page-section-copy"/>
          <div dangerouslySetInnerHTML={{__html: table}} className="sg-logged-in-landing-page-section-table"/>
        </div>
      </div>
    </div>
  )
}

export default ArrivalTransportation