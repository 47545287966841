import React from "react";
import PropTypes from "prop-types";

import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";


const Header = ({
  kbyg_independent_content,
  language
}) => {
  const header_item = getItemBySlug(kbyg_independent_content, 'kbyg-page-header');
  return (
    <div className="sg-logged-in-landing-page-header">
      <div className="container">
        <div className="sg-logged-in-landing-page-header-inner">
          <div className="sg-logged-in-landing-page-header-title">
            Cisco x Paris 2024
          </div>
          <div
            dangerouslySetInnerHTML={{__html: getCopy(header_item, {language})}}
            className="sg-logged-in-landing-page-header-copy"
          />
        </div>
      </div>
    </div>
  );
};

export default Header