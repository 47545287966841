import React from "react";
import PropTypes from "prop-types";
class RegistrationPageSignifiers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { page } = this.props;
    return (
      <div className="sg-reg-fb-page-signifiers col-xs-12">
        <img src={`/images/registration/p${page+1}.png`} />
      </div>
    )
  }
}

export default RegistrationPageSignifiers;
