import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
class RsvpVerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      reasoning: "",
      submitting: false,
    }
    this.verifyEmail = this.verifyEmail.bind(this);
  }


  verificationPages() {
    return [
      {
        cms_id: "rsvp-confirm-verify-email-page-copy",
        id: "confirm",
        title:"We look forward to welcoming you to the Olympic Games Paris 2024.",
        subtitle:"As this is an invitation-only event, please ensure the email entered below matches the email on your official invitation.",
        onSuccessUrl: "/rsvp/confirm"
      },
      {
        cms_id: "rsvp-change-date-verify-email-page-copy",
        id: "change_date",
        title:"We would not want you to miss the Olympic Games Paris 2024!",
        subtitle:"We have noted that you are unavailable on the proposed date but we may have another slot for you. Enter your e-mail below and we will get back to you with other alternatives (subject to availability).",
        onSuccessUrl: "/rsvp/change_date"
      },
      {
        cms_id: "rsvp-decline-verify-email-page-copy",
        id: "decline",
        title:"Thank you for your response. We are sorry you are unable to attend at the Olympic Games Paris 2024.",
        subtitle:(
          <>
            Please confirm your unavailability by entering your email address. Should you want to specify the reason for your unavailability, please comment below.
          </>
        ),
        afterInputText: (
          this.getLanguage() == "fr" ? (
            <>
             Si vous souhaitez revenir sur votre choix, merci de nous contacter dans les quinze jours à réception de votre invitation à <a href="mailto:ciscojointhegames@auditoire.com.">ciscojointhegames@auditoire.com</a>.
            </>
          ) : (
            <>
              Should you change your mind, please contact us in the next fifteen days: <a href="mailto:ciscojointhegames@auditoire.com.">ciscojointhegames@auditoire.com</a>.
            </>
          )
        ),
        includeReasoning: true,
        verificationUrl: "/rsvp/decline_post",
        onSuccessUrl: "/rsvp/decline",
      }
    ].map(x=>{
      const { cms_section } = this.props;
      if(x.cms_id) {
        x.title = getTitle(getItemBySlug(cms_section, x.cms_id), {
          language: this.getLanguage()
        });
        x.subtitle = (<div dangerouslySetInnerHTML={{__html: getCopy(getItemBySlug(cms_section, x.cms_id),{
          language: this.getLanguage()
        })}}/>);
      }
      return x
    })
  }

  getCurrentPage() {
    const { page_id } = this.props;
    console.log({page_id})
    const pages = this.verificationPages();
    return pages.find(page => page.id == (page_id||"confirm"));
  }


  verifyEmail(e) {
    e.preventDefault()
    const { features } = this.props;
    const { emailValue, reasoning,  selectedDate, submitting} = this.state;
    const currentPage = this.getCurrentPage();
    const { verificationUrl } = currentPage;
    if(submitting) {
      return;
    }
    this.setState({
      submitting: true
    })
    fetchAPI(verificationUrl ? verificationUrl : `/rsvp/verify_email`,(json)=>{
      this.setState({
        submitting: false
      })
      let error = null;
      let attendee = null
      if(json){
        attendee = json.attendee;
        error = json.error;
      }else {
        
      }

      if( error ) {
        console.log({error})
        this.setState({
          error: error
        })
      } else if (!error && attendee) {
        window.location = `${this.getCurrentPage().onSuccessUrl}?id=${attendee}&language=${this.getLanguage()}`
      }else {

      }
    } ,{
      method: 'POST',
      body: {
        email: emailValue||"",
        reasoning: reasoning||"",
        language: this.getLanguage(),
      }
    })
  }

  getLanguage() {
    const { language } = this.props;
    return language||"en"
  }

  allCopy() {
    return {
      enter_email: {
        "en": "Enter Email Address*",
        "fr": "Entrez votre adresse mail*"
      },
      reason_for_unavailability: {
        "en": "Reason for unavailability (optional)",
        "fr": "Motif d’indisponibilité (optionnel)"
      },
      indicates_required: {
        "en": "* indicates a required answer",
        "fr": "* Champ obligatoire"
      },
      button: {
        "en": "Continue",
        "fr": "Confirmer"
      }
    }
  }

  getCopy(key) {
    return this.allCopy()[key][this.getLanguage()]
  }


  render() {
    const { submitting, emailValue, error, reasoning } = this.state;
    const currentPage = this.getCurrentPage()
    return (
      <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form" style={{textAlign:"left"}}>
        <div className="sg-rsvp-copy" style={{maxWidth:"600px"}}>
          <div className="sg-rsvp-copy-title ">
            {currentPage.title}
          </div>
          <br/>
          {currentPage.subtitle}
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <div className="sg-rsvp-copy" style={{fontSize:"14px"}}>
            {this.getCopy("enter_email")}
          </div>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
          <br/>

          {currentPage.includeReasoning ? (
            <>
              <div className="sg-rsvp-copy" style={{fontSize:"14px"}}>
                {this.getCopy("reason_for_unavailability")}
              </div>
              <input
                value={reasoning}
                onChange={(e) => this.setState({
                  reasoning: e.target.value
                })}
                name="reasoning"
                autocomplete="off"
                className="form-control"
              />
              <br/>
            </>
          ):""}
        </div>
        {currentPage.afterInputText ? (
          <div className="sg-rsvp-copy">
            <br/>
            {currentPage.afterInputText}
          </div>
        ):""}
        {error ? (
          <div className="att-proam-registration-email-validation-error">
            {error}
          </div>
        ):""}
        <div style={{"textAlign": "right", "marginTop": "15px","lineHeight": "1em"}} className="registration-section-copy registration-section-copy sg-rsvp-small-copy">
          {this.getCopy("indicates_required")}
        </div>
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <button
            type="button"
            onClick={this.verifyEmail}
            className="sg-button sg-submit-button"
            style={{
              minWidth: "200px",
            }}
          >
            {submitting ? (<span className="loader" />) :
              this.getCopy("button")
            }
          </button>
        </div>
      </form>
    );
  }
}

export default RsvpVerifyEmail;
