import React, { useState } from "react";
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
import EventName from "./parts/EventName"
import ExclusiveExperience from "./parts/ExclusiveExperience"
import Welcome from "./parts/Welcome"
import ProgramOverview from "./parts/ProgramOverview";
import Accomodations from "./parts/Accomodations";
import WorthKnowing from "./parts/WorthKnowing";
import ContactUs from "./parts/ContactUs";
import LinkBar from "./parts/LinkBar";
const NonLoggedInLandingPage = ({
  language,
  wave_name,
  cms_prefix,
  cms_section
}) => {
  const [currentLanguage, setCurrentLanguageState] = useState(language);

  const setCurrentLanguage = (language) => {
    const url = window.location.href;
    const param="language"
    const paramVal=language
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    var rows_txt = temp + "" + param + "=" + paramVal;
    const newUrl = baseURL + "?" + newAdditionalURL + rows_txt;
    window.location.href = newUrl;
  }
  
  const getCmsItem = (slug) => {
    return getItemBySlug(cms_section, cms_prefix+slug);
  }

  const getCmsCopyBySlug = (slug) => {
    console.log({x: cms_prefix+slug, y: getCmsItem(slug)});
    return getCopy(getCmsItem(slug), {
      language: currentLanguage
    });
  }

  const renderLanguageSwap = () => {
    return (
      <div className="sg-nllp-sg-language-swap sg-nllp-section ">
        <div className="sg-nllp-section-container">
          <div className="sg-nllp-language-swap-inner">
            <button className={`sg-nllp-language-swap-button ${currentLanguage == "en" ? "active" : ""}`} onClick={()=>setCurrentLanguage("en")}>EN</button>
            <span className={`sg-nllp-language-swap-divider`}>|</span>
            <button className={`sg-nllp-language-swap-button ${currentLanguage == "fr" ? "active" : ""}`} onClick={()=>setCurrentLanguage("fr")}>FR</button>
          </div>
        </div>
      </div>
    )
  }  

  return (
    <div className="sg-non-logged-in-landing-page">
      {renderLanguageSwap()}
      <LinkBar language={currentLanguage} setCurrentLanguage={setCurrentLanguage} cms_section={cms_section} getCmsItem={getCmsItem}/>
      <EventName content={getCmsCopyBySlug('event-name')}/>
      <ExclusiveExperience anchor={'an-exclusive-experience'} content={getCmsCopyBySlug('an-exclusive-experience')}/>
      <Welcome anchor="welcome" content={getCmsCopyBySlug('welcome')}/>
      <ProgramOverview anchor="program-overview" content={getCmsCopyBySlug('program-overview')}/>
      <Accomodations anchor="accommodations" content={getCmsCopyBySlug('accommodations')}/>
      <WorthKnowing anchor="worth-knowing" content={getCmsCopyBySlug('worth-knowing')}/>
      <ContactUs anchor="contact-us" content={getCmsCopyBySlug('contact-us')}/>
    </div>
  );
}

export default NonLoggedInLandingPage;

