import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";
import Select from 'react-select'
const HostChanger = ({
  tickets, 
  is_host,
  attendee,
  current_ticket,
  changeTicket
}) => {
  if(!(is_host && tickets && tickets.length > 1)) {
    return ""
  }

  return (
    <div id="host-changer" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-host-changer">
      <div className="container flex">
        
        <div className="sg-logged-in-landing-page-copy-side">
          Hello {attendee.name_first},
          <br/>
          <br/>
          Our records indicate that you will be attending multiple program dates.
          <br/>Please select the wave you’d like to view:
          <div className="sg-logged-in-landing-page-section-host-changer-select">
            <Select
              options={
                tickets.map(x=>{return {label: x.ticket.full_name, value: x.ticket.wave}})
              }
              value={{
                label: current_ticket.ticket.full_name,
                value: current_ticket.ticket.wave
              }}
              onChange={(option) => {
                console.log({
                  option
                })
                changeTicket(option.value)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HostChanger