import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import SaveTheDateForm from "./forms/SaveTheDateForm"
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";

class SaveTheDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      page: 0,
      attendee: null,
      emailValue: "",
      success: false,
    }
    this.attemptEmailCheck = this.attemptEmailCheck.bind(this);
    this.onAttendeeRSVP = this.onAttendeeRSVP.bind(this);
  }


  attemptEmailCheck(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/save_the_date/email_verification`,(json)=>{
      if(json && json.error == null){
        this.setState({
          page: 1,
          attendee: json.attendee,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  getEmailAndAssistantIfApplicable() {
    const { attendee } = this.props;
    if(attendee.registration_answers.assistantemail) {
      return `${attendee.email} and ${attendee.registration_answers.assistantemail}`;
    }
    return attendee.email
  }
  

  renderConfirmation(){
    const { attendee } = this.state;
    return (
      <div className="sg-rsvp-copy-subtitle">
        <div className="col-xs-12 sg-rsvp-copy-cms"
          dangerouslySetInnerHTML={{
            __html: getCopy(getItemBySlug(this.props.cms_section, 'rsvp-confirm-confirmation-page')).replace("#{email}",this.getEmailAndAssistantIfApplicable())
          }}
        />
        <div className="text-left col-xs-12">
          <a target="_blank" href="mailto:jointhegames@auditoire.com">
            <button type="button" style={{paddingTop: "10px", marginRight:"10px"}} className="sg-button sg-submit-button">
              Contact us
            </button>
          </a>
          <a href="/pages/landing_page">
            <button type="button" style={{paddingTop: "10px"}} className="sg-button sg-submit-button">
              Home page
            </button>
          </a>
        </div>
      </div>
    )
  }

  renderEmailForm(){
    const { emailValue, error } = this.state;
    return (
      <form src="#" onSubmit={this.declineEmail}>
        <div>
          <div className="row" >
            <div className="col-xs-12 sg-rsvp-copy-cms"
              dangerouslySetInnerHTML={{ __html: getCopy(getItemBySlug(this.props.cms_section, 'rsvp-confirm-email-validation')) }}
            />
          </div>
        </div>
        <div>
          <br/>
          <label className="sg-rsvp-copy-field-label">Enter Email Address*</label>
          <input
            name="email"
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="dark-form-control "
          />
        </div>
        {error ? (
          <b className="att-proam-registration-email-validation-error">
            <br/>
            {error}
          </b>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.attemptEmailCheck}
            className="sg-button sg-submit-button"
          >
            Continue
          </button>
        </div>
      </form>
    );
  }

  renderForm() {
    const { cms_section } = this.props;
    const { attendee } = this.state;
    return (
      <SaveTheDateForm
        cms_section={cms_section}
        submitCallback={this.onAttendeeRSVP}
        attendee={attendee}
      />
    )
  }

  onAttendeeRSVP(json) {
    this.setState({
      page: 2
    })
  }


  render() {
    switch(this.state.page) {
      case 0:
        return this.renderEmailForm()
      case 1:
        return this.renderForm()
      case 2:
        return this.renderConfirmation();
    }
  }
}

export default SaveTheDate;
