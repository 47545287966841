import React from "react";
const ProgramOverview = ({
  content,
  anchor
}) => {
  console.log({
    content
  })
  return (
  <div id={anchor} class="sg-nllp-section sg-nllp-section-program-overview">
    <div class="sg-nllp-section-container">
      <div class="row" style={{display:"flex"}}>
        <div class="hidden-xs col-sm-6 sg-nllp-program-overview-dancer-image"/>
        <div class="col-xs-12 col-sm-6">
          <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
      </div>
    </div>
  </div>
  );
}
 

export default ProgramOverview