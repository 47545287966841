import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const ContactUs = ({
  language,
  kbyg_independent_content
}) => {
  const header_item = getItemBySlug(kbyg_independent_content, 'kbyg-contact-us-headline');
  const copy_item = getItemBySlug(kbyg_independent_content, 'kbyg-contact-us-copy');

  return (
    <div id="contact-us" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-contact-us">
      <div className="container flex">
        <div className="sg-logged-in-landing-page-icon-side">
          <img src="/images/landing/contact-us.svg" />
        </div>
        <div className="sg-logged-in-landing-page-copy-side">
          <div dangerouslySetInnerHTML={{__html: getCopy(header_item, {language})}} className="sg-logged-in-landing-page-section-header"/>
          <div dangerouslySetInnerHTML={{__html: getCopy(copy_item, {language})}} className="sg-logged-in-landing-page-section-copy"/>
        </div>
      </div>
    </div>
  )
}

export default ContactUs