import React from "react";
const WorthKnowing = ({
  content,
  anchor
}) => {
  return (
    <div id={anchor} className="sg-nllp-section sg-nllp-section-worth-knowing">
      <div className="sg-nllp-section-container">
        <div dangerouslySetInnerHTML={{ __html: content }}/>
      </div>
    </div>
  )
}
 

export default WorthKnowing