import React from "react";
const Accomodations = ({
  content,
  anchor
}) => {
  return content ? (
    <div id={anchor} className="sg-nllp-section sg-nllp-section-event-accomodations">
      <div className="sg-nllp-section-container">
        <div dangerouslySetInnerHTML={{ __html: content }}/>
      </div>
    </div>
  ) : ""
}
 

export default Accomodations