
import React, { useState } from "react";
import LoggedInLandingPage from "./LoggedInLandingPage";
const LoggedInLandingPageHost = (props) => {

  const [currentTicketID, setCurrentTicketID] = useState(props.tickets[0].ticket.wave);

  const getCurrentTicket = () => {
    return props.tickets.find(ticket => ticket.ticket.wave == currentTicketID)
  }

  const getCurrentTicketContents = () => {
    const currentTicket = getCurrentTicket();
    console.log({
      currentTicket,
      currentTicketID
    })
    return props.current_ticket_contents.find(contents => contents.slug == `kbyg-${currentTicket.ticket.calendarwave}`)
  }

  const changeTicket = (id) => {
    console.log({id})
    setCurrentTicketID(id)
  }

  return (
    <LoggedInLandingPage
      {...props}
      is_host={true}
      changeTicket={changeTicket}
      current_ticket={getCurrentTicket()}
      current_ticket_contents={getCurrentTicketContents()}
    />
  );
};

export default LoggedInLandingPageHost