import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
import LanguageSwapper from "@layout/LanguageSwapper";
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
  }

  copyContent() {
    const {cms_section, language} = this.props
    if(language == "fr") {
      return {
        header: (
          <>
            Nous vous remercions pour votre intérêt à nous rejoindre aux Jeux
            <br/>
            Olympiques de Paris 2024
          </>
        ),
        error: "Il semble que votre compte CCI ne fonctionne pas. Merci de réessayer.",
        buttonText: "S’enregistrer",
        contactUsCopy: (
          <>
            Si vous avez une question ou que le problème persiste, merci de contacter 
            <br/>
            <a href="mailto:ciscojointhegames@auditoire.com">ciscojointhegames@auditoire.com</a>
          </>
        )
      }
    }
    return {
      header: (
        <>
          Thank you for your interest in joining us at the
          <br/>
          Paris 2024 Olympic Games
        </>
      ),
      error: "It looks like your CCI login didn't work. Please try again.",
      buttonText: "Register",
      contactUsCopy: (
        <>
          If you have any questions or persistent problems with the CCI login, please contact us at
          <br/>
          <a href="mailto:ciscojointhegames@auditoire.com">ciscojointhegames@auditoire.com</a>
        </>
      )
    }
  }

  render() {
    const {cms_section, error, language} = this.props
    const cms_id = 'rsvp-decline-confirmation-copy-1'
    return (
      <>
      <LanguageSwapper language={language} />
      <div className="sg-rsvp-container sg-rsvp-email-validation-container sg-full-height-container container">
        <div className="text-center">
          <div class="sg-rsvp-copy">
            <div class="sg-rsvp-copy-title">
              {this.copyContent()["header"]}
            </div>
            <br/>
            <br/>
            {error ? error : this.copyContent()["error"]}
          </div>
          <div class="att-proam-registration-email-validation-submit">
            <br/>
            <br/>
            <a href="/users/auth/okta">
            <button class="sg-button sg-submit-button" type="button">
              {this.copyContent()["buttonText"]}
            </button>
            </a>
          </div>
          <div class="sg-rsvp-copy">
            <br/>
            <br/>
            {this.copyContent()["contactUsCopy"]}
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default RegistrationEmailValidation