import React from "react";
import FormBuilder from "@layout/forms/FormBuilder";
import { getItemBySlug } from "@utility/cms/CmsUtility";
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import RegistrationPageSignifiers from "./RegistrationPageSignifiers";
class RsvpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participant: props.participant,
      submission: null,
    }
    this.callbackNonBlocking = this.callbackNonBlocking.bind(this);
  }

  callback(json) {

  }

  callbackNonBlocking(values, state, form_ref, form_gid){
    
  }

  getFullNameHtml(includeVerb = false) {
    const { current_ticket, participant, cms_section } = this.props;
    const { ticket } = current_ticket;
    const language = this.getParticipantLanguage()
    let date = language == "fr" ? ticket.full_name_html_french : ticket.full_name_html
    return date
  }

  getHostDates(participant) {
    const hostDatesMetadata = participant.metadata.find(x => x.field_slug == 'host-dates')
    console.log({
      hostDatesMetadata
    })
    if(hostDatesMetadata && hostDatesMetadata.value) {
      return hostDatesMetadata.value.split("//").map(x=>`${x}`).join("<br/>")
    }
    return ""
  }

  getReplacableStrings() {
    const { current_ticket, participant, cms_section } = this.props;
    const { ticket } = current_ticket;
    return [
      {key: '*|CUSTOM1|*', value: ticket ? this.getFullNameHtml(true) : ''},
      {key: '*|EMAIL|*', value: participant.email },
      {key: '*|email|*', value: participant.email },
      {key: '*|tour-of-covered-passsages-image|*', value: `<img style="padding-bottom:40px" class="sg-reg-fb-activity-image sg-reg-fb-activity-image-5" src="/images/registration/visite-passages-couverts-04.jpg"/>`},
      {key: '*|event_dates|*', value: this.getFullNameHtml(false)},
      {key: "*|bourse-de-commerce-image|*", value: `<img class="sg-reg-fb-activity-image sg-reg-fb-activity-image-1" src="/images/activities/activity-1.jpeg"/>`  },
      {key: "*|monnaie-de-paris-image|*", value: `<img class="sg-reg-fb-activity-image sg-reg-fb-activity-image-2" src="/images/activities/activity-2.jpeg"/>`},
      {key: "*|hotel-de-la-marine-image|*", value: `<img class="sg-reg-fb-activity-image sg-reg-fb-activity-image-3" src="/images/activities/activity-3.jpeg"/>`},
      {key: "*|paris-e-bike-tour-image|*", value: `<img class="sg-reg-fb-activity-image sg-reg-fb-activity-image-4" src="/images/activities/activity-4.jpeg"/>`},
      {key: "*|host_event_dates|*", value: this.getHostDates(participant)}
    ]
  }

  getParticipantLanguage() {
    const { participant } = this.state;
    const languageMetadata = participant.metadata.find(x => x.field_slug == 'language')
    if(languageMetadata && languageMetadata.value && languageMetadata.value == "Français") {
      return "fr" 
    }
    return "en"
  }

  getErrorMessages() {
    const language = this.getParticipantLanguage();
    if(language == "fr") {
      return {
        selectOption:"Sélectionnez une option pour continuer. Cliquez pour utiliser cette solution.",
        ensureAllFields:"Veuillez vous assurer que tous les champs ont été correctement remplis."
      }
    }
    return {}
  }

  

  render() {
    const { participant, form } = this.props;
    return (
      <FormBuilder
        key="registration-form"
        components={{
          pageSignifiers: RegistrationPageSignifiers
        }}
        ticket_info={[]}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        onSubmitNonBlocking={this.callbackNonBlocking}
        submitUrl={'/registration'}
        submitCallback={this.callback}
        type={'registration'}
        errorMessages={this.getErrorMessages()}
      />
    );
  }
}

export default RsvpForm;