import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const AnchorLinks = ({
  language,
  current_ticket,
  current_ticket_contents,
  attendee
}) => {

  const knows_arrival_transportation = (attendee.metadata.find(x=>x.field_slug == "knowntransportplant")||{}).value == "Yes"
  const hide_arrival_transportation = current_ticket.package.id == "heritage" || !knows_arrival_transportation || !current_ticket || !current_ticket_contents || current_ticket_contents.answers['disable-guest-transportation'] == "Yes"

  const hide_accommodations = !current_ticket || !current_ticket_contents || current_ticket.package.id == "daily"
  const sections = [
    {
      english: "Introduction",
      french: "Introduction",
      anchor: "introduction"
    },
    {
      english: "Personal Agenda",
      french: "Agenda personnel",
      anchor: "personal-agenda"
    },
    {
      english: "Accommodations",
      french: "Hebergement",
      anchor: "accommodations",
      hide: hide_accommodations,
    },
    {
      english: "Transport",
      french: "Transport",
      anchor: "transport",
      hide: !(current_ticket.package.id == "heritage")
    },
    {
      english: "Arrival Transportation",
      french: "Transport d'arrivee",
      anchor: "arrival",
      hide: hide_arrival_transportation
    },
    {
      english: "Departure Transportation",
      french: "Transport de depart",
      anchor: "departure",
      hide: hide_arrival_transportation
    },
    {
      english: "Before you leave",
      french: "Avant de partir",
      anchor: "before-you-leave"
    },
    {
      english: "Games",
      french: "Jeux",
      anchor: "games"
    },
    {
      english: "Assistance",
      french: "Assistance",
      anchor: "contact-us"
    }
  ].filter(x=>! x.hide)

  const renderAnchors = () => {
    return sections.map(anchor => {
      return (
        <a
          key={anchor.anchor}
          href={"#" + anchor.anchor}
          className="sg-logged-in-landing-page-anchor-link"
        >
          {anchor[language == "fr" ? "french" : "english"]}
        </a>
      )
    })
  }

  return (
    <div className="sg-logged-in-landing-page-anchor-section">
      {renderAnchors()}
    </div>
  )
}

export default AnchorLinks