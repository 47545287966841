import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
import { Formik, Field, Form } from "formik";
import { getCopy, getTitle, replaceCustomTags } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
class RsvpVerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: null,
      saving: false,
      noDatesPage: false
    }
  }

  onSubmit(values) {
    const { attendee } = this.props;
    if(values.selectedDates.length < 1) {
      this.setState({
        error: "Please select at least one option."
      })
      return;
    }
    if(this.state.saving){
      return;
    }
    this.setState({
      saving: true,
      error: null,
      noDatesPage: values.selectedDates.indexOf("None of these days") != -1
    })
    try {
      fetchAPI(`/rsvp/change_date_post`,(json)=>{
        if(json.error) {
          this.setState({
            error: json.error,
            saving: false
          })
        }else {
          this.setState({
            saving: false,
            success: true
          })
        }
      }, {
        method: 'POST',
        body: {
          gid: attendee.gid,
          email: attendee.email,
          no_dates: values.selectedDates.indexOf("None of these days") != -1,
          selected_dates: values.selectedDates.join(", "),
          selected_dates_array: values.selectedDates
        }
      })
    }catch(error) {
      console.log(error)
      this.setState({
        error: "An Error Occurred. Please try again.",
        saving: false
      })
    }

  }

  getInitialValues() {
    return {
      selectedDates: [],
    }
  }
  
  renderForm() {
    const { current_ticket } = this.props;
    const { error } = this.state;
    return (
      <Formik
      initialValues={this.getInitialValues()}
      onSubmit={(values, actions) => {
        this.onSubmit(values)
      }}
      render={(formikProps) => (
        <Form
          ref={this.formikRef}
        >
        <div className="sg-rsvp-copy">
          <div className="sg-rsvp-copy-title ">
          We are sorry you could not make it{current_ticket.ticket ? (<> {current_ticket.package.headerDateVerb} <span dangerouslySetInnerHTML={{__html: current_ticket.ticket.full_name_html+"."}}/></>) : ""} Please, select the dates below that suit you best and we will do our best to accommodate your request.
          </div>
          <br/>
          <br/>
          Please select all dates you are available:
          <br/>
          <br/>
        </div>
        <div className="">
          {this.renderCheckboxes(formikProps)}
        </div>
        <div className="sg-rsvp-copy" style={{fontSize:"12px"}}>
          <br/>
          Please note that this selection does not guarantee the availability of the dates.
        </div>
        <div className="">
        {error ? (
            <div style={{color: "red"}} className="sg-rsvp-error">
              <br/>
              {error}
            </div>
          ):""}
          <br/>
          <br/>
     
          <button
            className="sg-button sg-submit-button"
            type="submit"
            disabled={formikProps.values.selectedDates.length <= 0}
          >
            Submit
          </button>  
        </div>
        </Form>
    )}/>
    )
  }

  renderCheckboxes(formikProps) {
    const { current_ticket } = this.props;
    const { ticket } = current_ticket
    console.log(ticket);
    let dates = current_ticket.package.dates.filter(x=>x.offer_date_change !== false)

    if(ticket) {
      dates = dates.filter(x=> x.gid != ticket.gid)
    }
    dates = dates.concat([
      {full_name: "None of these days (unfortunately the invite will be considered declined)", label: "None of these days", value: "none", wave: "None of these days"}
    ])
    return dates.map(date => (
      <div className="sg-rsvp-checkbox">
        <label>
          <Field onChange={(e)=> this.updateCheckbox(e,formikProps)} type="checkbox" name="selectedDates" value={date.wave} />
          <span class="checkmark"/>
          {date.full_name}
        </label>
      </div>
    ))
  }

  updateCheckbox(e, formikProps) {
    let value = formikProps.values.selectedDates;
    const newValue = e.target.value;
    const noneValue = "None of these days"
    if(newValue == noneValue){
      value = [noneValue]
    }else {
      value = value.filter(x=> x != noneValue)
      if(value.indexOf(newValue) == -1){
        value = value.concat([newValue])
      }else {
        value =  value.filter(x=>x != newValue);
      }
    }
    formikProps.setFieldValue('selectedDates', value)
  }

  renderConfirmationPage() {
    const { attendee, cms_section} = this.props;
    const { noDatesPage } = this.state;
    const cms_item = getItemBySlug(cms_section, noDatesPage ? "rsvp-change-date-confirmation-copy-decline" : "rsvp-change-date-confirmation-copy-1")
    return (
      <>
        <div className="sg-rsvp-copy">
          <div className="sg-rsvp-copy-title ">
            {getTitle(cms_item)}
          </div>
          <br/>
          <div
            dangerouslySetInnerHTML={{__html: replaceCustomTags(getCopy(cms_item), [
              {key: "*|EMAIL|*",  value: attendee.email}
            ])}}
          />
        </div>
        <div class="att-proam-registration-email-validation-submit">
          <br />
          <br />
          <a href="mailto:ciscojointhegames@auditoire.com" style={{marginRight:"30px"}}>
          <button class="sg-button sg-submit-button" type="button">Contact us</button>
          </a>
        </div>
      </>
    )
  }



  render() {
    const { success } = this.state;
    return (
      <div className="">
        {success ? (
          this.renderConfirmationPage()
        ): (
          this.renderForm()
        )}
      </div>
    );
  }
}

export default RsvpVerifyEmail;
