import Cookies from "universal-cookie";

export function getRegForm(forms) {
  // TODO: Somehow pick between multiple reg forms, eventually
  return forms['forms'].find(form => form['form_type'] === 'registration');
}

export function getOrderedPages(form) {
  return form['pages'].sort((a, b) => {return a.sort_order - b.sort_order});
}

export function getOrderedQuestions(page) {
  return page['questions'].sort((a, b) => {return a.sort_order - b.sort_order});
}

export function splitObjectField(attribute_id) {
  try {
    return attribute_id.split('.');
  } catch (e) {
    console.log(e);
    return [];
  }
}

export function isMetaDataField(attribute_id) {
  const splitQuestionId = splitObjectField(attribute_id);
  return splitQuestionId.length > 2
}

export function getFormikValue(attribute_id, formikProps, type) {
  if (type === 'survey') {
    return getFormikSurveyValue(attribute_id, formikProps);
  }
  const splitQuestionId = splitObjectField(attribute_id);
  if (isMetaDataField(attribute_id)) {
    // Metadata Field
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant["metadata"][splitQuestionId[2]];
  } else {
    // Participant Field
    let formikPropsParticipant = formikProps.values["participant"];
    return formikPropsParticipant[splitQuestionId[1]];
  }
}

export function setFormikValue(value, attribute_id, formikProps, type) {
  if (type === 'survey') {
    setFormikSurveyValue(value, attribute_id, formikProps);
  } else {
    const splitQuestionId = splitObjectField(attribute_id);
    if (isMetaDataField(attribute_id)) {
      // Metadata Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant["metadata"][splitQuestionId[2]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    } else {
      // Participant Field
      let formikPropsParticipant = formikProps.values["participant"];
      formikPropsParticipant[splitQuestionId[1]] = value;
      formikProps.setFieldValue('participant', formikPropsParticipant);
    }
  }
}

export function getFormikSurveyValue(attribute_id, formikProps) {
  const splitQuestionId = splitObjectField(attribute_id);
  let formikPropsParticipant = formikProps.values["survey"];
  return formikPropsParticipant[splitQuestionId[0]];
}

export function setFormikSurveyValue(value, attribute_id, formikProps) {
  const splitQuestionId = splitObjectField(attribute_id);
  let formikPropsParticipant = formikProps.values["survey"];
  formikPropsParticipant[[splitQuestionId[0]]] = value;
  formikProps.setFieldValue('survey', formikPropsParticipant);
}

export function getFieldName(question, type) {
  if (type === 'survey') {
    return `survey[${question.attribute_id}]`;
  } else {
    return question.attribute_id;
  }
}

export function isChildEnabled(question, formikProps) {
  switch (question.parent_child_options.field_requirement) {
    case "any":
      return checkChildAnyRelation(question.parent_child_options.fields, formikProps);
    case "all":
      return checkChildAllRelation(question.parent_child_options.fields, formikProps);
    case "notany":
      return !checkChildAnyRelation(question.parent_child_options.fields, formikProps);
    case "notall":
      return !checkChildAllRelation(question.parent_child_options.fields, formikProps);
    default:
      return true;
  }
}

export function checkChildAnyRelation(fields, formikProps) {
  let passesRelation = false;
  fields.forEach(field => {
    let formikValue = getFormikValue(field.attribute_id, formikProps)
    if (checkMatchingField(field.value, formikValue)) {
      passesRelation = true
    }
  });
  return passesRelation;
}

export function checkChildAllRelation(fields, formikProps) {
  let passesRelation = true;
  fields.forEach(field => {
    let formikValue = getFormikValue(field.attribute_id, formikProps)
    if (!checkMatchingField(field.value, formikValue)) {
      passesRelation = false
    }
  });
  return passesRelation;
}

export function translateCSS(css) {
  // TODO: Implement css -> jsx styling
  let jsonCSS = {}
  if (css !== '') {
    try {
      jsonCSS = JSON.parse(css)
    } catch (e) {
    }
  }
  return jsonCSS;
}

export function getInitialFormValues(participant, form={}) {
  let initialValues = {
    participant: getInitialRegistrationAnswers(participant),
    form_gid: form.gid
  }
  initialValues["participant"]["metadata"] = getInitialMetadataValues(participant.metadata);
  return JSON.parse(JSON.stringify(initialValues));
}

export function getInitialRegistrationAnswers(participant) {
  let registration_collection = participant.registration_answers;

  // Move base participant answers into reg block
  registration_collection["email"] = participant["email"];
  registration_collection["name_first"] = participant['name_first'];
  registration_collection["name_last"] = participant['name_last'];
  registration_collection["work_phone"] = participant['work_phone'];
  registration_collection["job_title"] = participant['job_title'];
  registration_collection["company"] = participant['company'];
  registration_collection["work_address_country"] = participant['work_address_country'];
  registration_collection["work_address_city"] = participant['work_address_city'];
  registration_collection["status"] = participant['status'];


  return registration_collection;
}

export function checkMatchingField(fieldValue, formikValue) {
  if (Array.isArray(formikValue)) {
    return formikValue.includes(fieldValue);
  }
  if (fieldValue === formikValue) {
    return true
  }
  return false;
}

export function getInitialMetadataValues(metadata_block) {
  let metadata_collection = {}
  metadata_block.forEach(metadata => {
    metadata_collection[metadata.field_slug] = metadata.value;
  });
  return metadata_collection
}

export function submitRegForm(values, state, component, form_gid, url, callback=()=>{}) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  let form_data = new FormData();
  component.setState({
    loading: true,
  })

  for ( var key in values['participant'] ) {
    form_data.append(`participant[${key}]`, values['participant'][key]);
  }
  for ( var key in values['participant']['metadata'] ) {
    form_data.append(`metadata[${key}]`, values['participant']['metadata'][key])
  }
  form_data.append('form_gid', form_gid)

  fetch(url, {
    method: `POST`,
    redirect: "manual",
    body: form_data,
    headers: {
      "X-CSRF-Token": token,
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {
      window.scrollTo(0,0);
      component.setState({
        loading: false,
        page: state.page + 1
      })
      callback(json);
    } else {
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}

export function submitSurveyForm(values, state, component, form_gid, url, callback=()=>{}) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  let form_data = new FormData();
  component.setState({
    loading: true,
  })
  for ( var key in values['survey'] ) {
    form_data.append(`survey[${key}]`, values['survey'][key]);
  }
  form_data.append('form_gid', form_gid)
  form_data.append('email', values['email'])

  fetch(url, {
    method: `POST`,
    redirect: "manual",
    body: form_data,
    headers: {
      "X-CSRF-Token": token,
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {
      window.scrollTo(0,0);
      component.setState({
        loading: false,
        page: state.page + 1
      })
      callback(json);
    } else {
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}