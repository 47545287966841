import React from "react";
const LinkBar = ({
  cms_section,
  getCmsItem,
  language,
  setCurrentLanguage
}) => {


  const getSections = () => {
    return [
      'welcome',
      'program-overview',
      'accommodations',
      'worth-knowing',
      'contact-us',
    ].map(x=>{
      const item = getCmsItem(x);
      if(item){
        item.anchor = x;
      }
      return item
    }).filter(x=>x);
  }

  const renderLinks = () => {
    return getSections().map((x, i) => {
      return (
        <a className="sg-nllp-link-bar-link" key={i} href={`#${x.anchor}`}>
          {language == "en" ? x.answers['section-name-english'] : x.answers['section-name-french']}
        </a>
      )
    })
  }

  return (
    <div className="sg-nllp-link-bar">
      <div className="sg-nllp-link-bar">
        {renderLinks()}
      </div>
    </div>
  )
}
 

export default LinkBar