import React from "react";
import { getItemBySlug } from "@utility/cms/CmsUtility";

import { getCopy, getTitle } from "@utility/cms/LangUtility";

const ArrivalTransportation = ({
  language,
  kbyg_independent_content,
  current_ticket_contents,
  current_ticket,
  attendee,
  meeting_point_content
}) => {

  const knows_arrival_transportation = (attendee.metadata.find(x=>x.field_slug == "knowntransportplant")||{}).value == "Yes"

  if(!knows_arrival_transportation || !current_ticket || !current_ticket_contents || current_ticket_contents.answers['disable-guest-transportation'] == "Yes") {
    return <></>
  }

  const header_item = getItemBySlug(kbyg_independent_content, 'kbyg-your-arrival');

  const copySlug = language === 'fr' ? "arrival-transportation-french" : "arrival-transportation-english"
  const copy = current_ticket_contents['answers'][copySlug];


  const dashDatesToSlashDates = (date) => {
    try {
      const dateSplit = date.split("-");
      return dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0];
    }catch (e) {
      return ""
    }
  }


  const attendee_place_of_arrival = (attendee.metadata.find(x=>x.field_slug == "placeofarrival")||{}).value

  const arrival_meeting_point = meeting_point_content.items.find(x=>x.name == attendee_place_of_arrival)
  const arrival_time = (attendee.metadata.find(x=>x.field_slug == "providedarrivaltime")||{}).value
  const arrival_flight_number = (attendee.metadata.find(x=>x.field_slug == "arrivaltransportationnumber")||{}).value
  const table = getCopy(getItemBySlug(kbyg_independent_content, 'arrival-content')||"", {language}).replaceAll(
    "*|place-of-arrival|*",
    attendee_place_of_arrival
  ).replaceAll(
    "*|arrival-time|*",
    arrival_time
  ).replaceAll(
    "*|arrival-train-flight-no|*",
    arrival_flight_number
  )

  return (
    <>
    {current_ticket.package.id == "daily" ? (
      <div class="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-arrival  sg-logged-in-landing-page-section-departure" style={{padding: 0}}>
        <div class="container flex" style={{padding: 0}}>
        
        </div>
      </div>
    ):""}
    <div id="arrival" className="sg-logged-in-landing-page-section sg-logged-in-landing-page-section-arrival">
      <div className="container flex">
        <div className="sg-logged-in-landing-page-icon-side">
          <img src="/images/landing/icon_car_1.svg" />
        </div>
        <div className="sg-logged-in-landing-page-copy-side">
          <div dangerouslySetInnerHTML={{__html: getCopy(header_item, {language})}} className="sg-logged-in-landing-page-section-header"/>
          <div dangerouslySetInnerHTML={{__html: copy}} className="sg-logged-in-landing-page-section-copy"/>
          <div dangerouslySetInnerHTML={{__html: table}} className="sg-logged-in-landing-page-section-table"/>
          {arrival_meeting_point ? (<div dangerouslySetInnerHTML={{__html: getCopy(arrival_meeting_point, {language})}} className="sg-logged-in-landing-page-section-copy"/>):""}
        </div>
      </div>
    </div>
    </>
  )
}

export default ArrivalTransportation