import React from "react";
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
class LandingPageIndex extends React.Component {

  render() {
    const { cms_section } = this.props;
    return (
      <div class="sg-landing-page-section sg-landing-page-section-exclusive-invitation">
        <div class="container">
          <div class="row">
          <div class="text-center col-xs-12 hidden-md hidden-lg hidden-xl hidden-xxl">
           <img class="sg-landing-page-section-exclusive-invitation-image" style={{maxWidth:"80%", height: "auto"}} src="/images/landing/dancer-transparent.png"/>
          </div>
          <div class="col-xs-12 col-md-6">
          <div class="sg-landing-page-section-exclusive-invitation-header">
            {getTitle(getItemBySlug(cms_section, 'landing-section-1'))}
          </div>
          <div class="sg-landing-page-section-exclusive-invitation-copy" dangerouslySetInnerHTML={{ __html: getCopy(getItemBySlug(cms_section, 'landing-section-1')) }}/>
          </div>
          <div class="hidden-xs hidden-sm col-md-1"></div>
          <div class="hidden-xs hidden-sm col-md-5">
           <img class="sg-landing-page-section-exclusive-invitation-image" src="/images/landing/dancer-transparent.png"/>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPageIndex;

