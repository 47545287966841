
import React, { useState } from "react";
import Header from "./sections/Header";
import ContactUs from "./sections/ContactUs";
import Games from "./sections/Games";
import BeforeYouLeave from "./sections/BeforeYouLeave";
import PersonalAgenda from "./sections/PersonalAgenda";
import HotelAccomodations from "./sections/HotelAccomodations";
import Excite from "./sections/Excite";
import ArrivalTransportation from "./sections/ArrivalTransportation";
import DepartureTransportation from "./sections/DepartureTransportation";
import AnchorLinks from "./sections/AnchorLinks";
import HostChanger from "./sections/HostChanger";
const LoggedInLandingPage = ({
  kbyg_independent_content,
  current_ticket,
  wave_dependent_contents,
  meeting_point_content,
  current_ticket_contents,
  language,
  attendee,
  tickets,
  is_host,
  changeTicket
}) => {

  return (
    <div className="sg-logged-in-landing-page">
      <AnchorLinks  language={language} attendee={attendee} current_ticket={current_ticket} current_ticket_contents={current_ticket_contents} />
      <Header language={language} kbyg_independent_content={kbyg_independent_content}/>
      <Excite language={language} kbyg_independent_content={kbyg_independent_content}/>
      <HostChanger changeTicket={changeTicket} current_ticket={current_ticket} tickets={tickets} is_host={is_host} attendee={attendee}/>
      <PersonalAgenda language={language} current_ticket_contents={current_ticket_contents} kbyg_independent_content={kbyg_independent_content}/>
      <HotelAccomodations current_ticket={current_ticket} current_ticket_contents={current_ticket_contents} language={language} wave_dependent_contents={wave_dependent_contents} kbyg_independent_content={kbyg_independent_content}/>
      <ArrivalTransportation meeting_point_content={meeting_point_content} attendee={attendee} current_ticket={current_ticket} current_ticket_contents={current_ticket_contents} language={language} kbyg_independent_content={kbyg_independent_content}/>
      <DepartureTransportation meeting_point_content={meeting_point_content} attendee={attendee} current_ticket={current_ticket} current_ticket_contents={current_ticket_contents} language={language} kbyg_independent_content={kbyg_independent_content}/>
      <BeforeYouLeave language={language} kbyg_independent_content={kbyg_independent_content}/>
      <Games language={language} kbyg_independent_content={kbyg_independent_content}/>
      <ContactUs language={language} kbyg_independent_content={kbyg_independent_content}/>
    
    </div>
  );
};

export default LoggedInLandingPage