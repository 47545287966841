import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
class RegistrationInvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }


  renderModal() {
    const { session } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12 sg-registration-description-text">
          <b>Important: This is an invitation-only event.</b>
          <br/>
          <br/>
          Registration is exclusive to you and may not be transferred.
          <br/>
          <br/>
          Please ensure that the email address provided is that of the registrant. Once you have submitted your registration form, you will receive a confirmation email in your inbox. If you have questions regarding the registration process, please contact <a href="mailto:jointhegames@auditoire.com" target="_blank">cxoevents@cisco.com</a>.
        </div>
      </div>
    );
  }



  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
    });
  }

  render() {
    const { modalOpen } = this.state;
    const { outerClassNames, children } = this.props;
    const outerStyle = this.props.outerStyle || {};

    return (
      <>
        <div
          className={outerClassNames}
          style={outerStyle}
           onClick={() => this.toggleModal()}
        >
          {children}
        </div>
        <Modal
          className="white-background sg-speaker-modal"
          overlayClassName="sg-channel-survey-modal-overlay"
          isOpen={modalOpen}
          onRequestClose={this.toggleModal}
          contentLabel=""
        >
          <div className="sg-agenda-modal-close-button" onClick={this.toggleModal}> &#10005; </div>
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

export default RegistrationInvitationModal;
