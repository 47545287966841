import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
import { getCopy, getTitle } from "@utility/cms/LangUtility";
import { getItemBySlug } from "@utility/cms/CmsUtility";
class RsvpDeclineConfirmation extends React.Component {
  constructor(props) {
    super(props);
  }

  getLanguage() {
    const { language } = this.props;
    return language||"en"
  }
  
  render() {
    const {cms_section} = this.props
    const cms_id = 'rsvp-decline-confirmation-copy-1'
    return (
      <div>
        <div class="sg-rsvp-copy">
          <div class="sg-rsvp-copy-title">
            {getTitle(getItemBySlug(cms_section, cms_id), 
              {
                language: this.getLanguage()
              }
            )}
          </div>
          <br/>
          <br/>
          <div dangerouslySetInnerHTML={{__html: 
            getCopy(getItemBySlug(cms_section, cms_id), {
              language: this.getLanguage()
            })
          }}/>
        </div>
        <div class="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <a href="mailto:ciscojointhegames@auditoire.com" style={{marginRight:"30px"}}>
          <button class="sg-button sg-submit-button" type="button">{this.getLanguage() =="fr" ? "Contact" :"Contact us"}</button>
          </a>
        </div>
      </div>
    )
  }
}

export default RsvpDeclineConfirmation