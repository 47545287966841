import React, { useState } from "react";
const LanguageSwapper = ({
  language,
}) => {

  const setCurrentLanguage = (language) => {
    const url = window.location.href;
    const param="language"
    const paramVal=language
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    var rows_txt = temp + "" + param + "=" + paramVal;
    const newUrl = baseURL + "?" + newAdditionalURL + rows_txt;
    window.location.href = newUrl;
  }
  

  return (
    <div className="sg-nllp-sg-language-swap sg-nllp-section ">
      <div className="sg-nllp-section-container">
        <div className="sg-nllp-language-swap-inner">
          <button className={`sg-nllp-language-swap-button ${language == "en" ? "active" : ""}`} onClick={()=>setCurrentLanguage("en")}>EN</button>
          <span className={`sg-nllp-language-swap-divider`}>|</span>
          <button className={`sg-nllp-language-swap-button ${language == "fr" ? "active" : ""}`} onClick={()=>setCurrentLanguage("fr")}>FR</button>
        </div>
      </div>
    </div>
  )
}

export default LanguageSwapper;

