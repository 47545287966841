export function getCopy(cms_item, opts ={}) {
  //TODO Language Split
  if(cms_item){
    if(opts && opts['language']=='fr'){
      return cms_item['answers']['french-content-1']
    }
    return cms_item['answers']['english-content-1']
  }
  return opts["default"]||""
}

export function getTitle(cms_item, opts ={}) {
  //TODO Language Split
  if(cms_item){
    if(opts && opts['language']=='fr'){
      return cms_item['answers']['french-title']
    }
    return cms_item['answers']['title']
  }
  return opts["default"]||""
} 

export function replaceCustomTags(content, replacableStrings){
  if(replacableStrings){
    replacableStrings.forEach(replacableString => {
      content = content.replaceAll(replacableString.key, replacableString.value);
    })
  }
  return content
}